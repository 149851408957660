import React, { useState } from 'react';
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Container, Grid2, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Paper } from '@mui/material';
import { Fade } from 'react-awesome-reveal';

const bordeLayout = 0;

const Landing = ()  => {
    const url = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        email: '',
        nombre: '',
        tipo: '',
        mensaje: '',
    });
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents default form submission behavior


        if (!executeRecaptcha) {
            console.error("reCAPTCHA is not ready");
            return;
        }
        
        
        try {
            const captchaToken = await executeRecaptcha("submit");
            //console.log("CAPTCHA Token:", captchaToken);
            const response = await axios.post(`${url}/api/email/send`, {
                user_email: formData.email,
                name: formData.nombre,
                role: formData.tipo,
                message: formData.mensaje,
                captchaToken,
            });
            //console.log(response);
            if (response.status === 200) {
                alert('Revisa tu correo, recibiste un mail de confirmacion!');
            } else {
                alert('Por alguna razon fallo el envio del mail, intenta mas tarde porfavor.');
            }
            } catch (error) {
                if (error.response) {
                    // The request was made, and the server responded with a status code outside the 2xx range
                    console.error('Server responded with an error:', error.response.data);
                    console.error('Status code:', error.response.status);
            
                    // Log the full request details
                    console.error('Request method:', error.config.method.toUpperCase());
                    console.error('Request URL:', error.config.url);
                    console.error('Request headers:', error.config.headers);
                    console.error('Request data (body):', error.config.data);
            
                    alert(`Error: ${error.response.data.error || 'An error occurred on the server'}`);
                } else if (error.request) {
                    // The request was made, but no response was received
                    console.error('No response received from the server:', error.request);
            
                    // Log the full request details
                    console.error('Request method:', error.config.method.toUpperCase());
                    console.error('Request URL:', error.config.url);
                    console.error('Request headers:', error.config.headers);
                    console.error('Request data (body):', error.config.data);
            
                    alert('No response from the server. Please try again later.');
                } else {
                    // Something else happened in setting up the request
                    console.error('Error setting up the request:', error.message);
                    
                    // Log the full request details if available
                    if (error.config) {
                        console.error('Request method:', error.config.method.toUpperCase());
                        console.error('Request URL:', error.config.url);
                        console.error('Request headers:', error.config.headers);
                        console.error('Request data (body):', error.config.data);
                    }
            
                    alert(`An error occurred: ${error.message}`);
                }
            }
            
    };
        
    return (<>
      <Container>
           <Grid2 container border={bordeLayout} paddingTop={8} >
                <Grid2 item border={bordeLayout}>
                      <Box>
                        <a href='http://www.whitemirror.cl'>
                            <img width={50} height={50} src="/icono_wm.png"/>
                        </a>
                    </Box>
                </Grid2>
                <Grid2 item size={{lg: 2}}  border={bordeLayout} display={'flex'} alignItems='center'>
                    <Typography color='black' fontWeight={'bold'} variant='h6'>White Mirror</Typography>
                </Grid2>

{/*                 <Grid2 item flexGrow={1} border={bordeLayout} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} >
                    <Button >
                        <Typography color='#6b46c1' variant='h6' fontWeight={'bold'} >
                            Iniciar Sesión
                        </Typography>
                    </Button>
                    <Button >
                        <Typography color='#6b46c1' variant='h6' fontWeight={'bold'} >
                            Registro
                        </Typography>
                    </Button>
                </Grid2>
 */}           </Grid2>
           
           <Fade delay={20} direction='left' triggerOnce>
              <Grid2 container paddingTop={4} border={bordeLayout}>
                <Grid2 item size={{lg : 12}} border={bordeLayout} display={'flex'} alignItems={'center'} >
                 </Grid2>
                   <Grid2 item size={{lg : 6}} border={bordeLayout} display={'flex'} alignItems={'center'} >
                      <Box>
                       <Typography variant='h3' fontWeight={'bold'} paddingBottom={4}>
                          Encuentra tu terapia personalizada con <span>IA</span>
                       </Typography>
                      <div className="buttons">
                          <button className="btn btn-primary">IArtemisa</button>
                          <button className="btn btn-secondary">
                              <a href="#contact" style={{ textDecoration: "none", color: "black" }}>  
                                  Reserva tu cupo
                              </a>
                          </button>
                      </div>
                    </Box>
                  </Grid2>
                  <Grid2 item size={{lg : 6}} border={bordeLayout} display={'flex'} justifyContent={'flex-end'}>
                      <div className="hero-content">
                         <div className="hero-image">
                           <img className="octa" src="/ia_futuristic_teraphy.jpg" width={500} height={400} alt="IA Therapy Interface" />
                         </div>
                    </div>
                  </Grid2>
             </Grid2>
            </Fade>
           <Fade delay={20} direction='left' triggerOnce>
           <Grid2 container border={bordeLayout} borderColor={'blue'} paddingTop={4} justifyContent={"center"}>
               <Paper border={bordeLayout} elevation={24}>
                <div>
                  <zapier-interfaces-chatbot-embed 
                    is-popup='false' 
                    chatbot-id='cm2v7juot000b6ezp1jvazypi' 
                    height='600px' 
                    width='500px'>
                  </zapier-interfaces-chatbot-embed>
                </div>
               </Paper>
         </Grid2>
         </Fade>

        <Fade delay={20} direction='left' triggerOnce>
         <MyContainer>
           <section className="benefits">
            <h2 className="benefits-title">
                <div className="benefits-icon">
                    <i className="fas fa-star"></i>
                </div>
                Beneficios que puedes encontrar
            </h2>
            <div className="benefits-grid">
                <div className="benefit-item">
                    <h3>Personalización avanzada:</h3>
                    <p>Mediante nuestro algoritmo de emparejamiento, encuentra el terapeuta que mejor se adapta a ti!</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Sesiones transcritas:</h3>
                    <p>Guardamos un registro de tus sesiones para un análisis preciso y detallado.</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Acceso 24/7:</h3>
                    <p>Con nuestro Chatbot siempre disponible, te brindamos apoyo emocional las 24 horas del día.</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Fácil de usar:</h3>
                    <p>Todo el proceso de reservar y encontrar una sesión es rápido y simple desde nuestra app.</p>
                </div>
            </div>
           </section>

           </MyContainer>
         </Fade>

     <Fade delay={20} direction='left' triggerOnce>
      <MyContainer>
        <Grid2 item size={{lg: 12}}>
        <section className="contact" id="contact" style={{color :"#6b46c1"}}>
            <h2>Mantengámonos en contacto</h2>
            <p>¡Pronto habrá más para ti!</p>
            <p className="subtitle">Inscríbete y sé el primero en conocer nuestras novedades</p>
            
            <form className="form-grid" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Correo</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        />
                </div>
                
                <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                    />
                </div>
                
                <div className="form-group">
                    <label htmlFor="tipo">Yo soy...</label>
                    <select
                    id="tipo"
                    name="tipo"
                    value={formData.tipo}
                    onChange={handleChange}
                    >
                    <option value="">Selecciona una opción</option>
                    <option value="paciente">Paciente</option>
                    <option value="terapeuta">Terapeuta</option>
                    <option value="otro">Otro</option>
                    </select>
                </div>
                
                <div className="form-group">
                    <label htmlFor="mensaje">Quiero decir...</label>
                    <textarea
                    id="mensaje"
                    name="mensaje"
                    value={formData.mensaje}
                    onChange={handleChange}
                    style={{ width: '100%', height: '120px' }}
                    ></textarea>
                </div>

                
                {/* Enviar Button */}
                <div className="form-group">
                <button type="submit" className="btn btn-primary">
                    Enviar
                </button>
                </div>

            </form>
           </section>
          </Grid2>
        </MyContainer>
       </Fade>

       <Fade delay={20} direction='left' triggerOnce>
         <MyContainer>
          <footer>
             <div className="container">
                 <h2 className="footer-title">Contacto</h2>
                 <p>SÍGUENOS</p>
                 <div className="social-links">
                     <a href="https://www.instagram.com/whitemirror_app" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                         <InstagramIcon sx={{ fontSize: 35 }} color="primary"/>
                     </a>
                     <a href="https://www.linkedin.com/in/diego-pavez/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon sx={{ fontSize: 35 }} color="primary" />
                    </a>
                 </div>
                 <p>O envíanos un correo a:</p>
                 <a href="mailto:mejorsaludmental@gmail.com" className="footer-email">
                     mejorsaludmental@gmail.com
                 </a>
             </div>
          </footer>
         </MyContainer>
        </Fade>
    </Container>
    
        </>
    )
}

const  MyContainer = (props) => {
  return <>
      <Grid2 container border={bordeLayout} borderColor={'blue'} paddingTop={4} justifyContent={"center"}>
        {
            props.children
        }
       </Grid2>
  </>
}

export default Landing;