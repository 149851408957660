import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './components/Landing';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY?.trim();
  // console.log("holiaiaiaiaia",captchaSiteKey2);

  //const captchaSiteKey = "6LeNf5MqAAAAAASUxUmWPDDocTDa5a1GMpPr6Z5z";
  // Log the site key for debugging
  //console.log("CAPTCHA Site Key:", captchaSiteKey);

  if (!captchaSiteKey) {
    console.error("CAPTCHA_SITE_KEY is not defined. Ensure it is set correctly.");
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />

        </Routes>
      </BrowserRouter>

    </GoogleReCaptchaProvider>
  );
}

export default App;
